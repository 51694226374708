<template>
  <backoffice-container>
    <b-card v-if="!isLoading && form" class="px-1 pb-2">
      <div class="d-flex justify-content-between flex-wrap my-1 mb-2">
        <h4>{{ $t('backoffice.onboarding-form.subtitle') }}</h4>
        <div class="d-flex">
          <b-form-group label-for="languages" class="width-150 mb-0 mr-50">
            <v-select
              id="languages"
              v-model="selectedLanguage"
              :clearable="false"
              :searchable="false"
              label="text"
              :reduce="(text) => text.value"
              :placeholder="$t('form.select-placeholder')"
              :options="locales"
            />
          </b-form-group>
          <b-link :to="goToPreviewForm()" target="_blank">
            <b-button
              v-if="form.key"
              class=" ml-50"
              variant="outline-primary"
            >
              <feather-icon
                v-b-tooltip.hover.bottom
                icon="EyeIcon"
                size="14"
                class="text-primary pointer mr-50"
              />
              {{ $t('tooltips.crud.preview') }}
            </b-button>
          </b-link>
          <b-button
            v-b-modal.new-role
            variant="primary"
            class="ml-1"
            :disabled="Object.values(form.name).length === 0 || isSending"
            @click="handleCreateItem()"
          >
            <span
              v-if="isSending"
            ><b-spinner
               label="Sending..."
               variant="white"
               class="mr-50"
               small
             />
              {{ $t('backoffice.subscriptions.form.action.submit') }}
            </span>
            <span v-else>{{ $t('form.actions.save') }}</span>
          </b-button>
          <b-button
            v-if="form.key"
            class="ml-1"
            variant="outline-danger"
            @click="deleteItem(form, true)"
          >
            {{ $t('unshare.simple-title') }}
          </b-button>
          </b-link>
        </div>
      </div>
      <div>
        <b-row>
          <b-col cols="12" class="d-flex align-items-center p-0">
            <b-col cols="12" md="8">
              <b-form-group :label="$t('backoffice.onboarding-form.form.name')" label-for="name">
                <b-form-input
                  id="name"
                  v-model="form.name[selectedLanguage]"
                  type="text"
                  :placeholder="$t('form.type-placeholder')"
                  maxlength="50"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4" class="mt-1">
              <b-form-checkbox
                id="checkbox-1"
                v-model="form.isPublished"
                name="checkbox-1"
                switch
              >
                {{ $t('backoffice.onboarding-form.form.publish') }}
              </b-form-checkbox>
            </b-col>
          </b-col>
          <b-col cols="12" md="8">
            <b-form-group :label="$t('backoffice.onboarding-form.form.description')" label-for="description">
              <div
                id="description"
                class="border overflow-y-scroll p-1 pointer"
                style="height:365px; overflow-y: scroll !important; border-radius: 1.2rem"
                @click="openModalDescription(form.description, $t('backoffice.onboarding-form.form.description'))"
                v-html="form.description[selectedLanguage] || Object.values(form.description)[0] || $t('form.type-placeholder')"
              />
            </b-form-group>
          </b-col>
          <b-col color="12" md="4">
            <b-form-group :label="$t('backoffice.onboarding-form.form.image')" label-for="image" class="d-inline-block">
              <file-preview
                v-if="!form.imageURL && !image"
                :src="onbordingImage[selectedLanguage]"
                :placeholder-image="joinCommunityImage"
                mime-type="image"
                style="max-height:300px; height: 300px;
    width: 210px;"
              />
              <file-preview
                v-if="form.imageURL"
                :src="getImageResource(form.imageURL)"
                :placeholder-image="joinCommunityImage"
                mime-type="image"
                cancelable
                style="max-height:300px; height: 300px;
    width: 210px;"
                @cancel="form.imageURL = null"
              />
              <file-upload
                v-else
                v-model="image"
                type="image"
                cancelable
                :placeholder="$t('form-create-item.image-button')"
                :drop-placeholder="$t('form-create-item.drop-placeholder')"
                @cancel="image = null"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card v-if="!isLoading && form" :key="widgetKey" class="px-1 pb-2">
      <b-tabs
        class="mt-1"
      >
        <!-- Questions -->
        <b-tab
          active
        >
          <template #title>
            <div class="align-items-center d-flex">
              <h4 class="m-0">
                {{ $t('backoffice.onboarding-form.questions.title') }}
              </h4>
            </div>
          </template>
          <div class="d-flex justify-content-between flex-wrap mt-1 ">
            <div v-if="isDraggable" class="d-flex justify-content-end flex-wrap mb-1 w-100">
              <feather-icon
                v-b-tooltip.hover.bottom
                icon="SaveIcon"
                :title="$t('header.action.save-layout')"
                size="20"
                class="text-primary"
                @click="handleEditOrder()"
              />
              <feather-icon
                icon="XIcon"
                size="20"
                class="text-primary zindex-1 ml-1"
                @click="closeEdit"
              />
            </div>
            <div v-else class="w-100 mb-2 d-flex justify-content-end flex-wrap">
              <b-button
                v-if="questions && questions.length > 1"
                variant="primary"
                size="sm"
                class="text-primary d-flex align-items-center mr-50 create-item"
                @click="openEdit"
              >
                <feather-icon icon="Edit2Icon" variant="primary" size="18" />
                <p class="m-0 ml-50">
                  {{ $t('backoffice.onboarding-form.edit-order') }}
                </p>
              </b-button>
              <b-button
                variant="primary"
                size="sm"
                class="text-primary d-flex align-items-center create-item"
                @click="openModal"
              >
                <feather-icon icon="PlusIcon" variant="primary" size="18" />
                <p class="m-0 ml-50">
                  {{ $t('backoffice.onboarding-form.new-question') }}
                </p>
              </b-button>
            </div>
            <b-list-group v-if="questions && questions.length > 0" ref="container" class="w-100 ">
              <draggable
                v-if="isDraggable"
                v-model="questions"
                :list="questions"
                :move="checkMove"
                @start="dragging = true"
                @end="dragging = false"
              >
                <transition-group>
                  <b-list-group-item
                    v-for="(question, index) in questions"
                    :key="index"
                    no-body
                    draggable
                    class="x-scroll-element grid-item"
                  >
                    <question
                      :question="question"
                      :index="index"
                      @editModal="editModal"
                      @deleteItem="deleteItem"
                    />
                  </b-list-group-item>
                </transition-group>
              </draggable>

              <b-list-group-item
                v-for="(question, index) in questions"
                v-show="!isDraggable"
                :key="index"
                no-body
                draggable
                class="x-scroll-element"
              >
                <question
                  :question="question"
                  :index="index"
                  @editModal="editModal"
                  @deleteItem="deleteItem"
                />
              </b-list-group-item>
            </b-list-group>
            <!-- Container without data: Placeholder -->
            <b-row v-else class="horizontal-placeholder">
              <b-col cols="12">
                <img :src="onboardingFormPlaceholder">
              </b-col>
              <b-col cols="12">
                <p class="text-primary">
                  {{ $t('available.message', { itemName: $t('backoffice.onboarding-form.item-placeholder') }) }}
                </p>
              </b-col>
            </b-row>
          </div>
        </b-tab>

        <!-- Answers -->
        <b-tab>
          <template #title>
            <div class="align-items-center d-flex">
              <h4 class="m-0">
                {{ $t('backoffice.faqs.answers') }}
              </h4>
            </div>
          </template>
          <div class="d-flex align-items-center float-right">
            <b-button
              variant="primary"
              class="float-right ml-1"
              @click="exportCsv"
            >
              <div v-if="!isLoadingButton">
                {{ $t("backoffice.members.export") }}
              </div>
              <b-spinner v-else small class="spinner" />
            </b-button>
          </div>
          <form-responses :form-key="form.key" :questions="questions" />
        </b-tab>
      </b-tabs>
    </b-card>
    
    <!-- Quill editor modal -->
    <b-modal
      id="modal-create-description"
      :title="description.descriptionTitle"
      centered
      hide-footer
      size="lg"
    >
      <b-form>
        <b-form-group label-for="type" :label="description.descriptionTitle">
          <quill-editor
            v-model="description[selectedLanguage]"
            class="my-50"
            rows="8"
            maxlength="5000"
            :value-placeholder="$t('form.type-placeholder')"
            label-attribute="description"
          />
        </b-form-group>
        <b-button
          variant="primary"
          class="float-right"
          @click="closeModalDescription(description)"
        >
          <span>{{ $t('form.actions.save') }}</span>
        </b-button>
      </b-form>
    </b-modal>
    <!-- Create and Edit Modal -->
    <modal-create-question :question="question" :form="form" @editQuestion="editQuestion" />
    <!-- Unshare Modal -->
    <b-modal
      id="modal-unshare-questions-onboarding"
      v-model="isUnshareModalVisible"
      :title="$t('unshare.title', { item: $t('backoffice.onboarding-form.error-question') })"
      size="lg"
      hide-footer
    >
      <feather-icon size="40" icon="AlertTriangleIcon" class="d-block mx-auto text-danger mb-1 mt-2" />
      <div v-if="questionDelete">
        <h5 class="mb-2 px-2 text-center">
          {{ $t('backoffice.onboarding-form.delete-text') }}
        </h5>
        <p class="text-muted text-center mb-4">
          "{{ questionDelete.name[selectedLanguage] || Object.values(questionDelete.name)[0] }}"
        </p>
      </div>
      <div v-if="formDelete">
        <h5 class="mb-2 px-2 text-center">
          {{ $t('unshare.message', { item: $t('backoffice.onboarding-form.error-form') }) }}
        </h5>
        <p class="text-muted text-center mb-4">
          "{{ formDelete.name[selectedLanguage] || Object.values(formDelete.name)[0] }}"
        </p>
      </div>
      <div class="d-flex justify-content-center mb-2 flex-wrap">
        <b-button
          variant="primary"
          target="_blank"
          class="mt-25"
          @click="isUnshareModalVisible = false"
        >
          {{ $t('unshare.options.no') }}
        </b-button>
        <b-button
          variant="outline-danger"
          target="_blank"
          class="ml-md-1 mt-25"
          @click="handleRemoveItem()"
        >
          {{ $t('unshare.options.yes') }}
        </b-button>
      </div>
    </b-modal>
  </backoffice-container>
</template>

<script>
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
import OnboardingFormPlaceholder from '@/assets/images/placeholders/light/backoffice-onboarding-form.svg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import vSelect from 'vue-select';
import { quillEditor } from 'vue-quill-editor';
import draggable from 'vuedraggable';
import { getImageResource } from '@/@core/utils/image-utils';
import FileUpload from '@core/components/files/FileUpload.vue';
import FilePreview from '@core/components/files/FilePreview.vue';
import JoinCommunityImage from '@/assets/images/pages/join-community.svg';
import ExploreCommunity from '@/assets/images/pages/community-explore.svg';
import FormResponses from '@/backoffice/views/forms/components/Responses.vue';
import MembersPlaceholder from '@/assets/images/placeholders/light/members.svg';
import Question from '@/backoffice/views/forms/components/Question.vue';
import OnboardinformImageCa from '@/assets/images/pages/onboarding-form/onboardingFormDefaultPhoto_ca.svg';
import OnboardinformImageEs from '@/assets/images/pages/onboarding-form/onboardingFormDefaultPhoto_es.svg';
import OnboardinformImageEn from '@/assets/images/pages/onboarding-form/onboardingFormDefaultPhoto_en.svg';
import ModalCreateQuestion from '@/backoffice/views/forms/components/ModalCreateQuestion.vue';
import { randomString } from '@core/utils/utils';
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";

export default {
  name: 'BackofficeOnboardingForm',
  components: {
    BackofficeContainer,
    quillEditor,
    vSelect,
    draggable,
    FileUpload,
    FilePreview,
    Question,
    FormResponses,
    ModalCreateQuestion,
  },
  mixins: [ToastNotificationsMixin],

  data() {
    return {
      isLoading: false,
      isLoadingButton: false,
      selectedLanguage: this.currentLocale,
      selectedLanguageQuestions: '',
      languagesOptions: [],
      options: [{}, {}],
      name: { ca: '', es: '', en: '' },
      placeholder: { ca: '', es: '', en: '' },
      initialQuestion: { type: 'text' },
      initForm: {
        name: { ca: "Formulari d'inscripció", es: 'Formulario de inscripción', en: 'Onboarding form' },
        description: {},
        isPublished: false,
        isOnboarding: true,
        questions: [],
        imageSrc: null,
      },
      question: {},
      image: null,
      key: null,
      questionDelete: null,
      formDelete: null,
      isSending: false,
      dragging: false,
      description: {},
      isUnshareModalVisible: false,
      members: [],
      isDraggable: false,
      actualItems: [],
      firstItems: [],
      widgetKey: randomString(10),
    };
  },
  computed: {
    membersPlaceholder() {
      return MembersPlaceholder;
    },
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('backoffice.members.fullname'),
          sortable: true,
        },
      ];
    },
    types() {
      return [
        // Falta afegir el 'file' (no preparat a la vista d'onboarding auth)
        { text: this.$t('backoffice.onboarding-form.types.text'), code: 'text' },
        { text: this.$t('backoffice.onboarding-form.types.phone'), code: 'phone' },
        { text: this.$t('backoffice.onboarding-form.types.number'), code: 'number' },
        { text: this.$t('backoffice.onboarding-form.types.email'), code: 'email' },
        { text: this.$t('backoffice.onboarding-form.types.date'), code: 'date' },
        { text: this.$t('backoffice.onboarding-form.types.country'), code: 'country' },
        { text: this.$t('backoffice.onboarding-form.types.checkbox'), code: 'checkbox' },
        { text: this.$t('backoffice.onboarding-form.types.infotext'), code: 'infotext' },
        { text: this.$t('backoffice.onboarding-form.types.textarea'), code: 'textarea' },
        { text: this.$t('backoffice.onboarding-form.types.toggle'), code: 'boolean' },
        { text: this.$t('backoffice.onboarding-form.types.select'), code: 'select' },
        { text: this.$t('backoffice.onboarding-form.types.radio'), code: 'radio' },
        { text: this.$t('backoffice.onboarding-form.types.custom-field'), code: 'customField' },
        { text: this.$t('backoffice.onboarding-form.types.classifier'), code: 'classifier' },
        { text: this.$t('backoffice.onboarding-form.types.organization'), code: 'organization' },
        { text: this.$t('backoffice.onboarding-form.types.society'), code: 'society' },
      ];
    },
    onboardingFormPlaceholder() {
      return OnboardingFormPlaceholder;
    },
    joinCommunityImage() {
      return JoinCommunityImage;
    },
    onbordingImage() {
      return {
        ca: OnboardinformImageCa,
        en: OnboardinformImageEn,
        es: OnboardinformImageEs,
      };
    },
    exploreCommunity() {
      return ExploreCommunity;
    },
    locales() {
      return this.$store.getters.locales;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    customFields() {
      if (this.$store.getters.customFieldsBO) {
        const customFields = this.$store.getters.customFieldsBO.unpaginated;
        for (const customField of customFields) {
          customField.text = customField.name[this.currentLocale] || Object.values(customField.name)[0];
        }
        return customFields;
      } return [];
    },
    classifiers() {
      if (this.$store.getters.classifiersType) {
        const classifiers = this.$store.getters.classifiersType.unpaginated;
        for (const classifier of classifiers) {
          classifier.text = classifier.name[this.currentLocale] || Object.values(classifier.name)[0];
        }
        console.log(classifiers);
        return classifiers;
      } return [];
    },
    form() {
      if (this.$store.getters.forms.unpaginated) {
        for (const form of this.$store.getters.forms.unpaginated) {
          if (form.isOnboarding) {
            return form;
          }
        }
      }
      return this.initForm;
    },
    questions() {
      if (this.$store.getters.formQuestions.unpaginated.length > 0) {
        return this.$store.getters.formQuestions.unpaginated;
      }
      return [];
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
  },

  async created() {
    this.isLoading = true;
    this.selectedLanguage = this.currentLocale;
    await this.fetchData();
    this.question = {
      name: { ...this.name },
      placeholder: { ...this.placeholder },
      options: [...this.options],
      ...this.initialQuestion,
    };
    if (this.form.key) {
      await this.fetchQuestions(this.form.key);
    }
    this.isLoading = false;
  },
  methods: {
    async exportCsv() {
      this.isLoadingButton = true;
      const response = await this.$store.dispatch("exportFiles", {
        itemType: "backoffice/forms/exportResponses",
        requestConfig: {
          formKey: this.form.key,
        },
      });
      setTimeout(this.csv(response), 10000);
      this.isLoadingButton = false;
    },
    csv(response) {
      const hiddenElement = document.createElement("a");
      hiddenElement.href = response.data.file;
      hiddenElement.target = "_blank";
      hiddenElement.download = "Members.csv";
      hiddenElement.click();
    },
    customFieldSelect() {
      if (this.question.customField?.required) {
        this.question.rules = 'required';
      }
    },
    editQuestion(key) {
      this.widgetKey = randomString(10);
      this.fetchQuestions(key);
      this.options = [{}, {}];
      this.question = {
        name: { ...this.name },
        placeholder: { ...this.placeholder },
        options: [...this.options],
        ...this.initialQuestion,
      };
    },
    getImageResource(image) {
      return getImageResource(image);
    },
    translatedFieldName(field) {
      for (const row of Object.keys(field)) {
        if (field[row]?.length === 0 || !field[row]) {
          delete field[row];
        }
      }
      return translateTranslationTable(this.selectedLanguage, field);
    },
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'forms', page: 1, forceAPICall: true, requestConfig: { type: 'form', isOnboarding: true },
      });
    },
    async fetchQuestions(key) {
      await this.$store.dispatch('getItems', {
        itemType: 'formQuestions',
        forceAPICall: true,
        requestConfig: { formKey: key },
        page: 1,
        perPage: 100,
      });
    },
    async fetchClassifiersByType() {
      await this.$store.dispatch('getClassifiersBO', {
        key: this.$route.params.id,
      });
    },
    async openModal() {
      this.options = [{}, {}];
      this.question = {
        name: { ...this.name },
        placeholder: { ...this.placeholder },
        options: [...this.options],
        ...this.initialQuestion,
      };
      this.$bvModal.show('modal-create-questions-onboarding');
    },
    openModalDescription(description, title) {
      this.description = {};
      this.description = { ...description };
      this.description.descriptionTitle = title;
      this.$bvModal.show('modal-create-description');
    },
    closeModal() {
      this.$bvModal.hide('modal-create-questions-onboarding');
    },
    closeModalDescription(description) {
      this.form.description = {};
      description.descriptionTitle = '';
      this.form.description = { ...description };

      this.$bvModal.hide('modal-create-description');
    },

    async handleCreateItem() {
      this.isSending = true;
      const file = {};
      const itemDelete = {};
      if (this.image) {
        file.imageURL = this.image;
      } else if (this.form.imageURL !== null) {
        file.imageURL = this.form.imageURL;
      } else {
        file.imageURL = null;
        itemDelete.deleteImageURL = true;
      }

      try {
        if (this.form.key) {
          await this.$store.dispatch('editItem', {
            item: {
              itemType: 'forms',
              requestConfig: {
                name: this.form.name,
                key: this.form.key,
                description: this.form.description,
                isOnboardingForm: 1,
                isPublished: this.questions && this.questions.length > 0 ? !!this.form.isPublished : false,
                questions: this.questions,
                ...itemDelete,
              },
            },
            file,
          });
        }
        if (!this.form.key) {
          const response = await this.$store.dispatch('createItem', {
            item: {
              itemType: 'forms',
              requestConfig: {
                name: this.form.name,
                description: this.form.description,
                isOnboardingForm: 1,
                isPublished: this.questions && this.questions.length > 0 ? !!this.form.isPublished : false,
              },
            },
            file,
          });
          this.key = response.data;
        }
        // Aqui
        this.notifySuccess(
          this.$t('backoffice.onboarding-form.messages.success-form'),
        );
        this.options = [{}, {}];
        this.question = {
          name: { ...this.name },
          placeholder: { ...this.placeholder },
          options: [...this.options],
          ...this.initialQuestion,
        };
        this.closeModal();
        this.isSending = false;
      } catch {
        this.isSending = false;
        this.notifyError(this.$t("error-message.general-error"));
      }
    },
    async editModal(question) {
      this.question = { ...question };
      this.question.name = { ...question.name };
      this.question.placeholder = { ...question.placeholcer };
      this.options = question.options.length > 0 ? [...question.options] : [{}, {}];
      this.question.options = this.options;
      if (question.customFieldLink) {
        this.question.type = 'customField';
        this.question.customField = this.customFields.find((customField) => customField.key === question.customFieldLink.key);
      }
      if (question.classifierTypeLink) {
        console.log(question.classifierTypeLink.key);
        this.question.classifier = this.classifiers.find((classifierType) => classifierType.key === question.classifierTypeLink.key);
        console.log(this.question.classifier);
      }
      this.$bvModal.show('modal-create-questions-onboarding');
    },
    deleteItem(item, isForm = false) {
      if (isForm) {
        this.formDelete = item;
      } else {
        this.questionDelete = item;
      }
      this.isUnshareModalVisible = true;
    },
    async checkMove(event) {
      this.actualItems = [];
      this.actualItems = [...this.questions];
      const oldIndex = event.draggedContext.index;
      const newIndex = event.draggedContext.futureIndex;
      this.actualItems.splice(oldIndex, 1);
      this.actualItems.splice(newIndex, 0, event.draggedContext.element);
    },
    async handleEditOrder() {
      this.isSending = true;
      for (const [index, item] of this.actualItems.entries()) {
        item.order = index + 1;
      }
      await this.$store.dispatch('editItem', {
        noSet: true,
        item: {
          itemType: '/formQuestions/order',
          requestConfig: {
            formKey: this.form.key,
            questions: this.actualItems,
          },
        },
      });
      this.firstItems = [];
      this.firstItems = [...this.actualItems];
      this.isDraggable = false;
      this.isSending = false;
    },
    openEdit() {
      this.firstItems = [];
      this.firstItems = [...this.questions];
      this.isDraggable = true;
    },
    closeEdit() {
      this.$store.commit('SET_ITEMS', {
        type: 'formQuestions',
        forceAPICall: true,
        page: 1,
        data: {
          data: this.firstItems,
          meta: {
            current_page: 1,
            total: this.firstItems.length,
          },
        },
      });
      this.isDraggable = false;
    },
    /* Fi ordenar preguntas */

    async handleRemoveItem() {
      try {
        if (this.questionDelete) {
          await this.$store.dispatch('unshareItem', {
            item: {
              itemType: 'formQuestions',
              key: this.questionDelete.key,
            },
          });
        }
        if (this.formDelete) {
          if (this.questions.length > 0) {
            for (const question of this.questions) {
              await this.$store.dispatch('unshareItem', {
                item: { itemType: 'formQuestions', key: question.key },
              });
            }
          }
          await this.$store.dispatch('unshareItem', {
            item: { itemType: 'forms', key: this.formDelete.key },
          });
        }
        this.isUnshareModalVisible = false;
        this.notifySuccess(
          this.$t('unshare.toast-messages.success', {
            item: this.questionDelete
              ? this.$t('backoffice.onboarding-form.error-question')
              : 'backoffice.onboarding-form.error-form',
          }),
        );
        this.formDelete = null;
        this.questionDelete = null;
      } catch {
        this.notifyError(this.$t('unshare.toast-messages.error', { item: this.questionDelete ? 'question' : 'form' }));
      }
    },
    addOption(options) {
      options.push({});
    },
    deleteOption(options) {
      if (options.length > 2) {
        options.pop();
      }
    },
    goToPreviewForm() {
      return {
        name: 'onboarding-form',
        params: {
          communityId: this.$route.params.communityId,
        },
        query: {
          preview: true,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.vs__dropdown-toggle {
  padding: 3.5px 0 7px !important;
  min-width: 160px;
}
.number {
  height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;
}.grid-item {
  box-shadow: 0px 0px 6px -2px $primary !important;
}
</style>
